<script>
	import logo from "@assets/logo.svg";
	import favicon from "@assets/favicon.png";
	import Spinner from "./lib/Spinner.svelte";
	import axios from "axios";

	const api = axios.create({
		baseURL: import.meta.env.VITE_API_URL,
	});

	let email = "";
	let password = "";
	let authToken = "";
	let busy = false;
	let error = "";
	let done = false;

	async function login() {
		busy = true;
		error = "";
		try {
			const res = await api.post("/v2/auth/login", {
				username: email,
				password: password,
			});
			console.log(res.data);
			authToken = res.data.auth_token;
		} catch (e) {
			console.error(e);
			error = "There was an issue logging in. Please try again.";
		} finally {
			busy = false;
		}
	}

	async function deleteMe() {
		busy = true;
		error = "";
		try {
			await api.post("/v3/Account/RequestDeletion", null, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			});
			done = true;
		} catch (e) {
			console.error(e);
			error = "There was an issue requesting deletion. Please try again.";
		} finally {
			busy = false;
		}
	}
</script>

<svelte:head>
	<link rel="icon" type="image/png" href={favicon}/>
</svelte:head>

<main>
	<img id="logo" src={logo} alt="homeAR Logo" />
	<h2>Account Deletion</h2>
	{#if busy}
		<Spinner />
	{:else if done}
		<p>
			Your deletion request has been submitted. You can now close this
			tab.
		</p>
	{:else if authToken}
		<p>
			Are you sure you want to delete your account? Your account will be
			permanently deleted within the next 48 working hours. This includes
			all your personal details and shortlist.
		</p>
		<button on:click={deleteMe}>Yes, please delete my account</button>
	{:else}
		<p>Please login here:</p>
		<form on:submit|preventDefault={login}>
			<input
				type="email"
				placeholder="Email address..."
				required
				bind:value={email}
			/>
			<input
				type="password"
				placeholder="Password..."
				required
				bind:value={password}
			/>
			<button type="submit">Login</button>
		</form>
	{/if}
	{#if error}
		<p class="error">{error}</p>
	{/if}
</main>

<style lang="scss">
	main {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0.5rem;

		max-width: 40rem;
		margin: 0 auto;
	}

	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0.8rem;
		width: 100%;
	}

	input,
	button {
		width: 100%;
		flex-grow: 1;
		padding: 0.5rem 0.8rem;
		box-sizing: border-box;
		border-radius: 9999px;
		border: 0;
		box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
	}

	#logo {
		max-width: 30rem;
	}

	.error {
		color: rgb(247, 72, 72);
	}
</style>
